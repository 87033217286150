import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)"></g>
    </g>
    <text x="28" y="65" fill="currentColor" fontSize="12px" fontFamily="Consolas, serif">
      Loading...
    </text>
  </svg>
);

export default IconLoader;
